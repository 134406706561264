.form-input{
    color: #828282;
  font-family: Arial;
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  padding:15px 20px;
  margin-bottom:20px;
  margin-right:30px;
  width:90%;
}

.form-input::selection{
    border:blue;
}