@-webkit-keyframes bag{
    0% {
        background-position: top;
      }
      20% {
        background-position: bottom;
      }
      40% {
        background-position: left;
      }
      60% {
        background-position: right;
      }
      80% {
        background-position: center;
      }
      100% {
        background-position: initial;
      }
}

.footer{
    height: 276px;
    width: 100%;
    background-color: #8053F6;
    display: flex;
    justify-content: center;
    align-items: center;
    animation-name: bag;
    animation-duration: 10s;
    animation-iteration-count: 2;
    animation-direction: alternate;
    animation-timing-function: linear;

}



.footer-text{
    font-family: "Arial";
    height: 22px;
    color: #FFF;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
}